import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';
import Footer from '../components/Footer';
import BaseLoader from '../components/utils/BaseLoader';
import BasePageContent from '../components/utils/BasePageContent';
import formatAmount from '../components/utils/formatAmount';
import Seo from '../components/utils/Seo';
import Layout from '../templates/layout';

const mapStateToProps = s => ({
  getLiabilityInterestCalculation: s.getLiabilityInterestCalculation,
});

const title = 'Kalkulator odsetek ustawowych';

const minDate = new Date('2016-01-01');
const regExp = /^\d+([\.\,]\d{1,2})?$/;

const INITIAL_ERRORS = {
  amount: null,
  startDate: null,
  endDate: null,
};

const InterestCalculator = ({ getLiabilityInterestCalculation, actions }) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [expectedDate, setExpectedDate] = useState(null);
  const [actualDate, setActualDate] = useState(null);
  const [errors, setErrors] = useState(INITIAL_ERRORS);

  const validateAmount = () => {
    let errMessage = null;
    if (amount && !regExp.test(amount)) {
      errMessage = 'Nieprawidłowy format kwoty';
    }

    setErrors({ ...errors, amount: errMessage });
  };

  const maxStartDate =
    actualDate && !isNaN(actualDate) ? dateFns.subDays(actualDate, 1) : null;

  const validateStartDate = () => {
    let errMessage = null;
    if (expectedDate) {
      if (isNaN(expectedDate)) {
        errMessage = 'Nieprawidłowy format daty';
      } else if (dateFns.isBefore(expectedDate, minDate)) {
        errMessage = 'Wybierz późniejszą datę';
      } else if (maxStartDate && dateFns.isAfter(expectedDate, maxStartDate)) {
        errMessage = 'Wybierz wcześniejszą datę';
      }
    }
    setErrors({ ...errors, endDate: null, startDate: errMessage });
  };

  const minEndDate = dateFns.addDays(
    expectedDate && !isNaN(expectedDate) ? expectedDate : minDate,
    1
  );

  const validateEndDate = () => {
    let errMessage = null;
    if (actualDate) {
      if (isNaN(actualDate)) {
        errMessage = 'Nieprawidłowy format daty';
      } else if (dateFns.isBefore(actualDate, minEndDate)) {
        errMessage = 'Wybierz późniejszą datę';
      }
    }
    setErrors({ ...errors, startDate: null, endDate: errMessage });
  };

  useEffect(() => {
    if (getLiabilityInterestCalculation) {
      setLoading(false);
      setResult(getLiabilityInterestCalculation);
    }
  }, [getLiabilityInterestCalculation]);

  useEffect(validateAmount, [amount]);
  useEffect(validateStartDate, [expectedDate]);
  useEffect(validateEndDate, [actualDate]);

  const handleClear = () => {
    setAmount('');
    setExpectedDate(null);
    setActualDate(null);
    setErrors({ ...INITIAL_ERRORS });
    setResult(null);
  };

  const isValid = () => {
    return (
      amount &&
      expectedDate &&
      actualDate &&
      Object.values(errors).every(err => !err)
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!isValid()) return;
    setLoading(true);

    actions.getLiabilityInterestCalculation({
      amount: amount.replaceAll(',', '.'),
      expectedDate: dateFns.format(expectedDate, 'yyyy-MM-dd'),
      actualDate: dateFns.format(actualDate, 'yyyy-MM-dd'),
    });
  };

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Layout>
            <BasePageContent title={title}>
              {result ? (
                <Alert severity='info'>
                  Pamiętaj: wyliczenia mają charakter poglądowy i nie mogą
                  stanowić podstawy roszczeń w stosunku do Fiberpay sp. z o.o.
                </Alert>
              ) : (
                <Alert severity='info' variant='outlined'>
                  Uzupełnij dane aby wyświetlić wynik kalkulacji
                </Alert>
              )}
              <Box my={4}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box>
                      <Grid
                        container
                        spacing={4}
                        component='form'
                        onSubmit={handleSubmit}
                      >
                        <Grid item xs={12} md={3.6}>
                          <TextField
                            id='amount'
                            label='Kwota'
                            variant='outlined'
                            pattern='[0-9.,]+'
                            name='amount'
                            value={amount}
                            placeholder='Kwota'
                            maxLength='10'
                            onChange={e => setAmount(e.target.value)}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  PLN
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{ maxLength: 10 }}
                            error={!!errors.amount}
                            helperText={errors.amount || ''}
                          />
                        </Grid>
                        <Grid item xs={12} md={3.6}>
                          <DatePicker
                            autoOk
                            variant='inline'
                            inputVariant='outlined'
                            label='Data, od której naliczane są odsetki'
                            value={expectedDate}
                            name='startDate'
                            format='dd/MM/yyyy'
                            onChange={setExpectedDate}
                            minDate={minDate}
                            maxDate={maxStartDate}
                            required
                            invalidDateMessage='Nieprawidłowy format daty'
                            renderInput={props => (
                              <TextField
                                {...props}
                                fullWidth
                                error={!!errors.startDate}
                                helperText={errors.startDate || ''}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3.6}>
                          <DatePicker
                            autoOk
                            variant='inline'
                            inputVariant='outlined'
                            label='Termin uregulowania zobowiązania'
                            value={actualDate}
                            name='endDate'
                            minDate={minEndDate}
                            format='dd/MM/yyyy'
                            onChange={setActualDate}
                            required
                            invalidDateMessage='Nieprawidłowy format daty'
                            renderInput={props => (
                              <TextField
                                {...props}
                                fullWidth
                                error={!!errors.endDate}
                                helperText={errors.endDate || ''}
                              />
                            )}
                          />
                        </Grid>
                        <Grid container justifyContent="right" item md={1.2}>
                          <Button
                            disableElevation
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={!isValid() || loading}
                          >
                            Oblicz
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  {loading && <BaseLoader loading />}
                  {result && (
                    <Paper sx={{ height: '100%' }} variant='outlined'>
                      <Typography variant='h6' px={2} pt={2}>
                        Wynik
                      </Typography>
                      <Box px={2} py={4}>
                        <Grid container spacing={2}>
                          {[
                            {
                              key: 'Odsetki do zapłaty:',
                              value: formatAmount(
                                result.totalInterestAmount,
                                'PLN'
                              ),
                            },
                            {
                              key: <>Kwota zobowiązania wraz z odsetkami:</>,
                              value: formatAmount(
                                result.liabilityWithInterestAmount,
                                'PLN'
                              ),
                            },
                            {
                              key: 'Ilość dni:',
                              value: result.totalDays,
                            },
                          ].map(({ key, value }) => (
                            <Grid container item alignItems='end' xs={12}>
                              <Grid item xs={12} sm={6}>
                                {key}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  variant='h5'
                                  sx={{
                                    textAlign: { xs: 'start', sm: 'end' },
                                  }}
                                >
                                  <b>{value}</b>
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                      <Table>
                        <TableHead>
                          <TableCell>Okres</TableCell>
                          <TableCell>Ilość dni</TableCell>
                          <TableCell>Stopa procentowa</TableCell>
                          <TableCell>Kwota odsetek</TableCell>
                        </TableHead>
                        <TableBody>
                          {result.liabilityPerPeriod.map(
                            ({ startDate, endDate, rate, amount, days }) => (
                              <TableRow>
                                <TableCell>
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    {startDate}
                                  </span>
                                  {<> -&nbsp;</>}
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    {endDate}
                                  </span>
                                </TableCell>
                                <TableCell>{days}</TableCell>
                                <TableCell>{`${rate}%`}</TableCell>
                                <TableCell>
                                  {formatAmount(amount, 'PLN')}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </BasePageContent>
          </Layout>
        </Box>
        <Box sx={{ backgroundColor: '#f5f5f5' }}>
          <Footer maxWidth="lg" paddingX="0" />
        </Box>
      </Box>
    </>
  );
};

export const Head = ({ location }) => (
  <Seo
    location={location}
    title={title}
    description='Kalkulator odsetek ustawowych nalicza odsetki na podstawie kwoty zobowiązania oraz wymaganego i rzeczywistego terminu płatności'
  />
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterestCalculator);
